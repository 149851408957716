import '../assets/tabler/scss/bundle.scss'
import '../assets/sass/main.scss'

import * as singleSpa from 'single-spa'

// import '../assets/js/header';
import $ from 'jquery';
import _ from 'underscore'
import femses from './fems.json'

// declareChildApplication(
//   'fems-auth',
//   () =>
//     import(/* webpackChunkName: "fems-auth" */ '../apps/fems-auth/init/fems-auth.module'),
//   activeWhen('fems-auth')
// );

$.getJSON('/config.json', data => {
  window.appEnvConfig = data

  setWebsiteMetadata(window.appEnvConfig);

  document.body.classList.add(window.appEnvConfig.theme.name)
  _.each(femses, function(fems) {
    if (fems.path) {
      let div = document.createElement('div')
      div.setAttribute('class', fems.framework + '-wrapper')
      div.setAttribute('id', fems.name)
      document.getElementById('fems-wrapper').appendChild(div)
    }
  })

  singleSpa.registerApplication(
    'fems-navbar',
    () =>
      import(/* webpackChunkName: "fems-navbar" */ '../apps/fems-navbar/init/fems-navbar.module'),
    () => true
  )
  singleSpa.registerApplication(
    'fems-configurations',
    () =>
      import(/* webpackChunkName: "fems-configurations" */ '../apps/fems-configurations/init/fems-configurations.module'),
    activeWhen('fems-configurations')
  )
  singleSpa.registerApplication(
    'fems-footer',
    () =>
      import(/* webpackChunkName: "fems-footer" */ '../apps/fems-footer/init/fems-footer.module'),
    () => true
  )
  singleSpa.registerApplication(
    'fems-auth',
    () =>
      import(/* webpackChunkName: "fems-auth" */ '../apps/fems-auth/init/fems-auth.module'),
    activeWhen('fems-auth')
  )
  singleSpa.registerApplication(
    'fems-agents',
    () =>
      import(/* webpackChunkName: "fems-agents" */ '../apps/fems-agents/init/fems-agents.module'),
    activeWhen('fems-agents')
  )
  singleSpa.registerApplication(
    'fems-access-control',
    () =>
      import(/* webpackChunkName: "fems-access-control" */ '../apps/fems-access-control/init/fems-access-control.module'),
    activeWhen('fems-access-control')
  )
  singleSpa.registerApplication(
    'fems-dashboard',
    () =>
      import(/* webpackChunkName: "fems-dashboard" */ '../apps/fems-dashboard/init/fems-dashboard.module'),
    activeWhen('fems-dashboard')
  )
  singleSpa.registerApplication(
    'fems-developers',
    () =>
      import(/* webpackChunkName: "fems-developers" */ '../apps/fems-developers/init/fems-developers.module'),
    activeWhen('fems-developers')
  )
  singleSpa.registerApplication(
    'fems-featured-promotions',
    () =>
      import(/* webpackChunkName: "fems-featured-promotions" */ '../apps/fems-featured-promotions/init/fems-featured-promotions.module'),
    activeWhen('fems-featured-promotions')
  )
  singleSpa.registerApplication(
    'fems-finances',
    () =>
      import(/* webpackChunkName: "fems-finances" */ '../apps/fems-finances/init/fems-finances.module'),
    activeWhen('fems-finances')
  )
  singleSpa.registerApplication(
    'fems-fliers',
    () =>
      import(/* webpackChunkName: "fems-fliers" */ '../apps/fems-fliers/init/fems-fliers.module'),
    activeWhen('fems-fliers')
  )
  singleSpa.registerApplication(
    'fems-hotels',
    () =>
      import(/* webpackChunkName: "fems-hotels" */ '../apps/fems-hotels/init/fems-hotels.module'),
    activeWhen('fems-hotels')
  )
  singleSpa.registerApplication(
    'fems-hotel-bookings',
    () =>
      import(/* webpackChunkName: "fems-hotel-bookings" */ '../apps/fems-hotel-bookings/init/fems-hotel-bookings.module'),
    activeWhen('fems-hotel-bookings')
  )
  singleSpa.registerApplication(
    'fems-hotel-search',
    () =>
      import(/* webpackChunkName: "fems-hotel-search" */ '../apps/fems-hotel-search/init/fems-hotel-search.module'),
    activeWhen('fems-hotel-search')
  )
  singleSpa.registerApplication(
    'fems-locations',
    () =>
      import(/* webpackChunkName: "fems-locations" */ '../apps/fems-locations/init/fems-locations.module'),
    activeWhen('fems-locations')
  )
  singleSpa.registerApplication(
    'fems-manual-bookings',
    () =>
      import(/* webpackChunkName: "fems-manual-bookings" */ '../apps/fems-manual-bookings/init/fems-manual-bookings.module'),
    activeWhen('fems-manual-bookings')
  )
  singleSpa.registerApplication(
    'fems-markets',
    () =>
      import(/* webpackChunkName: "fems-markets" */ '../apps/fems-markets/init/fems-markets.module'),
    activeWhen('fems-markets')
  )
  singleSpa.registerApplication(
    'fems-static-keys',
    () =>
      import(/* webpackChunkName: "fems-static-keys" */ '../apps/fems-static-keys/init/fems-static-keys.module'),
    activeWhen('fems-static-keys')
  )
  singleSpa.registerApplication(
    'fems-translations',
    () =>
      import(/* webpackChunkName: "fems-translations" */ '../apps/fems-translations/init/fems-translations.module'),
    activeWhen('fems-translations')
  )
  singleSpa.registerApplication(
    'fems-transfers',
    () =>
      import(/* webpackChunkName: "fems-transfers" */ '../apps/fems-transfers/init/fems-transfers.module'),
    activeWhen('fems-transfers')
  )
  singleSpa.registerApplication(
    'fems-reports',
    () =>
      import(/* webpackChunkName: "fems-reports" */ '../apps/fems-reports/init/fems-reports.module'),
    activeWhen('fems-reports')
  )
  singleSpa.registerApplication(
    'fems-users',
    () =>
      import(/* webpackChunkName: "fems-users" */ '../apps/fems-users/init/fems-users.module'),
    activeWhen('fems-users')
  )
  singleSpa.registerApplication(
    'fems-pages',
    () =>
      import(/* webpackChunkName: "fems-pages" */ '../apps/fems-pages/init/fems-pages.module'),
    activeWhen('fems-pages')
  )

  singleSpa.start()

  if (getCurrentAppPath() === '') {
    window.location.replace(window.location.origin + '/#!/auth/login')
  }
})

function setWebsiteMetadata(config) {
  $.getJSON((config.apiUrl + 'configurations/system-configuration/website-metadata'), (res) => {
    $('head title').html(res.website_metadata.title);
  });
}

function activeWhen(femsName) {
  let fems = _.findWhere(femses, { name: femsName })
  return function() {
    return 'fems-' + getCurrentAppPath() === fems.name
  }
}

function getCurrentAppPath() {
  let currentAppPath
  if (window.location.hash.indexOf('/') === -1) {
    currentAppPath = window.location.hash
  } else {
    currentAppPath = window.location.hash.split('/')[1]
  }
  return currentAppPath
}

// singleSpa.registerApplication('login', () => import('../apps/login/login.js'), pathPrefix('/login'));
// singleSpa.registerApplication('register', () => import('../apps/register/register.js'), pathPrefix('/register'));
// singleSpa.registerApplication('dashboard', () => import('../apps/dashboard/dashboard.js'), pathPrefix('/dashboard'));
// singleSpa.registerApplication('app-2', () => import('../apps/app2/app2.js'), pathPrefix('/app2'));

// function pathPrefix(prefix) {
//   return function (location) {
//     return location.pathname.startsWith(`${prefix}`);
//   };
// }
